<template>
  <div>
    <v-progress-linear
      class="loader"
      indeterminate
      v-show="isLoading"
      color="loaderColor"
    />
    <div :class="`default-tags-container${isLoading ? ' is-loading' : ''}`">
      <div class="header">
        <v-btn
          depressed
          rounded
          text
          router
          class="header-navigation-button"
          v-can:DICT_READ
          :to="{ name: 'categories' }"
        >
          {{ $t("menu.category") }}
        </v-btn>
        <v-btn
          depressed
          rounded
          text
          router
          class="header-navigation-button"
          v-can:DICT_READ
          :to="{ name: 'organizations' }"
        >
          {{ $t("menu.organizations") }}
        </v-btn>
        <v-btn
          depressed
          rounded
          text
          router
          class="header-navigation-button"
          v-can:DICT_READ
          :to="{ name: 'departments' }"
        >
          {{ $t("menu.departments") }}
        </v-btn>
        <v-btn
          color="navigationPageButtons"
          class="header-navigation-button white--text"
          rounded
          v-can:DICT_READ
        >
          {{ $t("menu.defaultTags") }}
        </v-btn>
      </div>

      <div class="content">
        <div class="category-selector">
          <BaseSelect
            :items="categories"
            v-model="selectedCategoryId"
            :name="$t('complaints.category')"
            required
            solo
            item-text="text"
            item-value="id"
          />
        </div>

        <div class="subcategories">
          <div class="title-header">
            {{ $t("complaints.subcategories").toUpperCase() }}
          </div>
          <SubcategoryTagsSection
            v-for="(item, index) in defaultSubcategoryTags"
            :key="`${index}`"
            :allTags="allTags"
            :subcategoryName="item.subcategoryName"
            :selectedTags="item.tags"
            :disabled="!hasUserPermissionsToEdit"
            @onChange="
              (value) => addTagToSubcategory(item.subcategoryId, value)
            "
            @onRemoveTag="
              (tagId) => removeTagFromSubcategory(item.subcategoryId, tagId)
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DictionaryService from "@/services/DictionaryService";

import SubcategoryTagsSection from "@/components/Dictionary/SubcategoryTagsSection";

export default {
  name: "DefaultTagsList",
  components: { SubcategoryTagsSection },
  mounted() {
    this.getCategories();
    this.getAllTags();
  },
  data() {
    return {
      isLoading: false,
      categories: [],
      subcategories: [],
      allTags: [],
      selectedCategoryId: null,
      defaultSubcategoryTags: [],
    };
  },
  computed: {
    ...mapGetters("settings", ["getUserRestrictions"]),
    hasUserPermissionsToEdit() {
      const userPermissions = this.getUserRestrictions
        ? this.getUserRestrictions.global
        : [];

      return userPermissions.some((item) => item.code === "DICT_WRITE");
    },
  },
  methods: {
    getAllTags() {
      this.isFetching = true;
      DictionaryService.GetAllTags().then((response) => {
        this.allTags = response;
        this.isFetching = false;
      });
    },
    getCategories() {
      this.isLoading = true;
      DictionaryService.GetAllCategories()
        .then((response) => {
          this.categories = response;

          if (response.length === 1) {
            this.selectedCategoryId = response[0].id;
          }
        })
        .finally(() => (this.isLoading = false));
    },
    getTagsForAllSubategories(categoryId) {
      this.isLoading = true;
      DictionaryService.GetSubcategories(categoryId).then((response) => {
        this.subcategories = response;

        const promises = [];

        response
          .map((item) => item.id)
          .forEach((subcategoryId) => {
            promises.push(DictionaryService.GetSubcategoryTags(subcategoryId));
          });

        Promise.all(promises)
          .then((values) => {
            this.defaultSubcategoryTags = [];
            response.forEach((subcategory, index) => {
              this.defaultSubcategoryTags.push({
                subcategoryId: subcategory.id,
                subcategoryName: subcategory.text,
                tags: values[index],
              });
            });
          })
          .finally(() => (this.isLoading = false));
      });
    },
    getTagsForSingleSubcategory(subcategoryId) {
      this.isLoading = true;

      const currentTags = [...this.defaultSubcategoryTags];

      DictionaryService.GetSubcategoryTags(subcategoryId)
        .then((response) => {
          const modifiedSubcategory = currentTags.find(
            (item) => item.subcategoryId === subcategoryId
          );
          modifiedSubcategory.tags = response;
          this.defaultSubcategoryTags = [...currentTags];
        })
        .finally(() => (this.isLoading = false));
    },
    addTagToSubcategory(subcategoryId, tagValue) {
      this.isLoading = true;

      DictionaryService.AddTagToSubcategory(subcategoryId, tagValue)
        .then(() => {
          this.getAllTags();
          this.getTagsForSingleSubcategory(subcategoryId);
        })
        .finally(() => (this.isLoading = false));
    },
    removeTagFromSubcategory(subcategoryId, tagId) {
      this.isLoading = true;

      DictionaryService.RemoveTagFromSubcategory(subcategoryId, tagId)
        .then(() => {
          this.getTagsForSingleSubcategory(subcategoryId);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    selectedCategoryId(newValue) {
      if (newValue) {
        this.getTagsForAllSubategories(newValue);
      }
    },
  },
};
</script>

<styles scoped lang="scss">
.loader {
  margin: -12px -12px 0 -12px;
}

.default-tags-container {
  padding: 24px 12px 12px 12px;

  &.is-loading {
    margin-top: 8px;
  }

  .header {
    .header-navigation-button {
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }

  .content {
    margin-top: 30px;

    .category-selector {
      max-width: 300px;
    }

    .subcategories {
      background-color: white;
      box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 1);
      border-radius: 5px;
      padding: 20px;

      .title-header {
        color: $text-title;
        border-bottom: 1px solid $border-line;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
    }
  }
}
</styles>

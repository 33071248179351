<template>
  <div :class="isMobile ? 'tags-section-mobile' : 'tags-section'">
    <div class="tag-control">
      <div class="name">
        {{ subcategoryName }}
      </div>

      <div class="field">
        <v-combobox
          :value="isFocus ? selectedTags : []"
          :items="availableTags"
          :search-input.sync="searchedTag"
          :hide-no-data="hideNewItemMessage"
          :disabled="disabled"
          @change="onChangeHandler"
          @blur="onBlurHandler"
          @focus="onFocusHandler"
          hide-selected
          solo
          hide-details
        >
          <template #selection></template>
          <template #no-data>
            <v-list-item>
              <span class="subheading">
                {{ $t("complaints.enterToAddTag") }}
              </span>
              <v-chip color="chipColor" dark small>{{ searchedTag }}</v-chip>
            </v-list-item>
          </template>
        </v-combobox>
      </div>
    </div>

    <div class="tags">
      <v-chip
        color="chipColor"
        text-color="textColor"
        class="tag"
        v-for="(tag, index) in selectedTags"
        :key="index"
        :disabled="disabled"
      >
        <TooltipTruncate :title="tag.text" class="pr-2">
          {{ tag.text }}
        </TooltipTruncate>
        <v-icon
          class="icon"
          small
          color="iconButton"
          @click="onRemoveTag(tag.id)"
        >
          fa-times
        </v-icon>
      </v-chip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SubcategoryTagsSection",
  components: {},
  props: {
    disabled: { type: Boolean, default: false },
    subcategoryName: { type: String },
    allTags: { type: Array, default: () => [] },
    selectedTags: { type: Array, default: () => [] },
  },
  data() {
    return {
      searchedTag: null,
      isFocus: false,
    };
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
    hideNewItemMessage() {
      const tagNames = this.allTags.map((item) => item.text.toLowerCase());
      const searchTagName =
        this.searchedTag && this.searchedTag.trim()
          ? this.searchedTag.toLowerCase()
          : null;

      return !searchTagName || tagNames.includes(searchTagName);
    },
    availableTags() {
      return this.allTags.filter(
        (tag) =>
          !this.selectedTags.some((selectedTag) => selectedTag.id === tag.id)
      );
    },
  },
  methods: {
    onChangeHandler(newItem) {
      if (typeof newItem === "string" && newItem.trim() !== "") {
        this.$emit("onChange", newItem);
      } else if (typeof newItem === "object" && newItem) {
        this.$emit("onChange", newItem.text);
      }
    },
    onBlurHandler() {
      this.isFocus = false;
    },
    onFocusHandler() {
      this.isFocus = true;
    },
    onRemoveTag(tagId) {
      if (tagId) {
        this.$emit("onRemoveTag", tagId);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tags-section,
.tags-section-mobile {
  &:nth-child(even) {
    background-color: $content-section-background;
  }

  .tags {
    .tag {
      box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 1);
      margin: 5px;

      .icon {
        margin-left: 5px;
      }
    }
  }
}

.tags-section {
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;

  .tag-control {
    flex: 0 0 40%;
    display: flex;
    align-items: center;
    min-width: 350px;
    max-width: 500px;

    .name {
      flex: 0 0 50%;
      padding-right: 5px;
    }

    .field {
      flex: 0 0 50%;
      padding-left: 5px;
    }
  }

  .tags {
    flex: 1 1 auto;
    margin-left: 10px;
  }
}

.tags-section-mobile {
  padding: 20px;

  .tag-control {
    margin-bottom: 10px;

    .name {
      margin-bottom: 10px;
    }
  }
}
</style>
